import $ from "jquery";

const ajaxWithToken = function(url, method, dataObject, token, success, error) {
    $.ajax({
        url: url,
        type: method,
        headers: {
            'Authorization':'Bearer '+token,
        },
        data: dataObject,
        success : function(data) {
            //console.log("success");
            success(data);
        },
        error : function(data) {
            //console.log("error");
            error(data);
        }
    });
};

export default ajaxWithToken;
