
import React, { Component } from 'react';
import './style.scss';
import Login from './js/components/Login';
import Overview from './js/components/Overview';
import Detail from './js/components/Detail';
import StickyBar from './js/components/StickyBar';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedIn:undefined,
            view:"start",
            detailId:undefined,
            r:0,
        };

        this.checkHash = this.checkHash.bind(this);
        this.onLoginChange = this.onLoginChange.bind(this);
        this.onLoginExpired = this.onLoginExpired.bind(this);
        this.showStart = this.showStart.bind(this);
        this.showDetail = this.showDetail.bind(this);
        window.ODT={};

        //--------------------------------------------------------------------------------------------------------------
        //dm-test SSO-cookie: adfs-k.dm-cloud.org

        let rootUrl = process.env.REACT_APP_ROOT_URL || "";
        //rootUrl="https://b2b-reseller.test2.oddity.agency";
        //rootUrl="https://b2b-reseller.dm-fb2.de";
        //rootUrl="https://b2b-reseller.dm.de";

        window.ODT.loginUrl = rootUrl + "/saml/login";
        window.ODT.orderUrl = rootUrl + "/api/backend/orders";
        window.ODT.downloadUrl = rootUrl + "/api/backend/download/order";
        window.ODT.downloadSmallAmountRequestsUrl = rootUrl + "/api/backend/download/small-amount-requests";

        //--------------------------------------------------------------------------------------------------------------
        window.ODT.debug=false; // shows 2 debug orders (only when orderUrl is not reachable)
        //--------------------------------------------------------------------------------------------------------------

        this.validHashes=["start", "detail"]; //"wait" excluded

        if(window.location.hash.substr(1)!=="" && !isNaN(window.location.hash.substr(1))){
            //console.log("store deeplink");
            window.localStorage.setItem('deeplink', window.location.hash.substr(1));
            window.location.hash="";
        }

        //console.log(process.env);

    }

    componentDidMount() {
        window.location = "#start";
        window.onhashchange = this.checkHash;
    }

    // CUSTOM ----------------------------------------------------------------------------------------------------------

    checkHash(){
        let hash=window.location.hash.substring(1);
        if(hash==="" || hash === undefined){
            hash = "start";
        }
        if(this.validHashes.indexOf(hash) !== -1){
            this.setState({
                view:hash,
            });
        }
    }

    showStart(){
        window.location = "#start";
        this.setState({
            view:"start",
            detailId:undefined,
            r:Math.random(),
        });
    }

    showDetail(id){
        window.location = "#detail";
        this.setState({
            view:"detail",
            detailId:id,
        });
    }

    // EVENTS ----------------------------------------------------------------------------------------------------------

    onLoginChange(loggedIn){
        this.setState({
            loggedIn,
        });

        if(loggedIn){
            setTimeout(function(){
                if(window.localStorage.getItem("deeplink")!==null){
                    //console.log("apply deeplink");
                    this.showDetail(window.localStorage.getItem("deeplink"));
                    window.localStorage.removeItem('deeplink');
                }
            }.bind(this), 1);
        }
    }

    onLoginExpired(){
        //alert("Ihr Login ist abgelaufen. Bitte melden Sie sich erneut an.");
        //this.onLoginChange(false);

        window.localStorage.removeItem("token");

        window.location.href=window.ODT.loginUrl;
    }

    // RENDER ----------------------------------------------------------------------------------------------------------

    render() {

        const {loggedIn, view, detailId, r} = this.state;

        let detail=<div/>;
        let overview=<div/>;
        if(loggedIn){
            detail=<Detail id={detailId} onLoginExpired={this.onLoginExpired}/>
            overview=<Overview r={r} onDetailClick={this.showDetail} onLoginExpired={this.onLoginExpired}/>
        }

        return (
            <div className="App">
                <StickyBar onLogoClick={this.showStart} onBackClick={this.showStart} onLoginChange={this.onLoginChange} white={loggedIn?false:true} back={(loggedIn && view==="detail")?true:false}/>
                <div className="odt-defaultLayout">
                    <div className="odt-defaultLayout-inner">
                        <div style={loggedIn===true?{display:"block"}:{display:"none"}}>
                            <div  style={view==="start"?{display:"block"}:{display:"none"}}>
                                {overview}
                            </div>
                            <div style={view==="detail"?{display:"block"}:{display:"none"}}>
                                {detail}
                            </div>
                        </div>
                        <div style={loggedIn===false?{display:"block"}:{display:"none"}}>
                            <div style={{height:"60px"}}/>
                            <div className="odt-centered">
                                <h2>Willkommen</h2>
                                <div style={{height:"10px"}}/>
                                <p>Bitte melden Sie sich an um fortzufahren.</p>
                                <div style={{height:"60px"}}/>
                                <Login url={window.ODT.loginUrl} onLoginChange={this.onLoginChange} type="button"/>
                            </div>
                            <div style={{height:"60px"}}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
