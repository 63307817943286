import React, { Component } from 'react';

import './Login.scss';

class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loggedIn:false,
        };

        this.onLoginClick=this.onLoginClick.bind(this);
        this.onLogoutClick=this.onLogoutClick.bind(this);
        this.checkLogin=this.checkLogin.bind(this);
        this.checkLocalStorage=this.checkLocalStorage.bind(this);

        if(window.ODT_LOGIN === undefined){
            window.ODT_LOGIN={};
            window.ODT_LOGIN.eventDispatcher=document.createElement("div");
        }
        window.ODT_LOGIN.eventDispatcher.addEventListener("change", this.checkLogin);
    }

    componentDidMount(){
        this.checkLogin();
    }

    // CUSTOM ----------------------------------------------------------------------------------------------------------

    checkLogin() {
        let loggedIn:false;
        loggedIn = this.checkLocalStorage();
        if(loggedIn === false){
            loggedIn = this.checkGetParameter();
        }

        //delete get parameter
        window.history.replaceState({}, document.title, "/");

        this.setState({
            loggedIn,
        });
        this.props.onLoginChange(loggedIn);
    }

    checkLocalStorage(){
        const token=window.localStorage.getItem("token");
        return (token !== null);
    }

    checkGetParameter(){

        function getSearchParameters() {
            var query = window.location.search.substr(1);
            return query !== null && query !== "" ? splitQuery(query) : {};
        }

        function splitQuery( query ) {
            var params = {};
            var queries = query.split("&");
            for ( var i = 0; i < queries.length; i++) {
                var tArr = queries[i].split("=");
                params[tArr[0]] = tArr[1];
            }
            return params;
        }

        var params = getSearchParameters();
        if(params.token !== undefined ){
            window.localStorage.setItem('token', params.token);
            return true;
        } else{
            return false;
        }
    }

    // EVENTS ----------------------------------------------------------------------------------------------------------


    onLoginClick(){
        window.location.href=this.props.url;
    }

    onLogoutClick(){
        window.localStorage.clear();

        var event;
        if(typeof(Event) === 'function') {
            event = new Event('change');
        } else{
            event = document.createEvent('Event');
            event.initEvent('change', true, true);
        }
        window.ODT_LOGIN.eventDispatcher.dispatchEvent(event);
    }

    // RENDER ----------------------------------------------------------------------------------------------------------

    render() {

        const {loggedIn} = this.state;
        const {type} = this.props;

        let className="odt-textButton";
        if(type === "button"){
            className="odt-button";
        }

        let button=<div className={className} onClick={this.onLoginClick}>Anmelden</div>;
        if(loggedIn){
            button=<div className={className} onClick={this.onLogoutClick}>Abmelden</div>;
        }

        return (
            <div className="odtLogin">
                {button}
            </div>
        );
    }
}


export default Login;
