import React, {Component} from 'react';
import './Pagination.scss'

class Pagination extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };


        this.onItemClick = this.onItemClick.bind(this);
        this.onRightArrowClick = this.onRightArrowClick.bind(this);
        this.onLeftArrowClick = this.onLeftArrowClick.bind(this);

    }

    //------------------------------------------------------------------------------------------------------------------
    // CUSTOM

    drawItem(id){
        const {page} = this.props;
        if(id === page){
            return <div key={id} data-id={id} className="odtPagination-item odtPagination-active">{id}</div>
        } else{
            return <div key={id} data-id={id} onClick={this.onItemClick} className="odtPagination-item odtPagination-clickable">{id}</div>
        }

    }

    drawDots(id){
        return <div  key={id} className="odtPagination-item">...</div>
    }

    //------------------------------------------------------------------------------------------------------------------
    // EVENTS

    onItemClick(e){
        this.props.onChange(parseInt(e.currentTarget.getAttribute("data-id")));
    }

    onRightArrowClick(){
        const {page, pages} = this.props;
        if(page<pages){
            this.props.onChange(page+1);
        }

    }

    onLeftArrowClick(){
        const {page} = this.props;
        if(page>1){
            this.props.onChange(page-1);
        }

    }

    render(){
        const {page, pages} = this.props;

        let items=[];


        if(pages>7){
            items.push(this.drawItem(1));
            if(page>4){
                items.push(this.drawDots("d1"));
            } else {
                for (let i = 2; i <= 5; i++) {
                    items.push(this.drawItem(i));
                }
            }
            if(page>4 && page<pages-3){
                items.push(this.drawItem(page-1));
                items.push(this.drawItem(page));
                items.push(this.drawItem(page+1));
            }
            if(page<pages-3){
                items.push(this.drawDots("d2"));
            } else{
                for (let i = pages-4; i <= pages-1; i++) {
                    items.push(this.drawItem(i));
                }
            }
            items.push(this.drawItem(pages));
        } else {
            for (let i = 1; i <= pages; i++) {
                items.push(this.drawItem(i));
            }
        }

        return (
            <div className="odtPagination">
                <svg
                    onClick={this.onLeftArrowClick}
                    className="odtPagination-arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20px"
                    height="24px"
                    viewBox="0 0 25 30"
                >
                    <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="round"
                        d="M16,8 L8,16 L16,24"
                    />
                </svg>
                {items}
                <svg
                    onClick={this.onRightArrowClick}
                    className="odtPagination-arrow"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="20px"
                    height="24px"
                    viewBox="0 0 25 30"
                >
                    <path
                        fill="none"
                        strokeWidth="2"
                        strokeLinecap="round"
                        d="M8,8 L16,16 L8,24"
                    />
                </svg>
            </div>
        );
    }
}

export default Pagination;
