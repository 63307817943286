const thounsandsHelper = function(number, digits) {
    if(digits===undefined){
        digits=0;
    }
    let string=number.toLocaleString('de-DE', { minimumFractionDigits: digits });
    return string;

};

export default thounsandsHelper;
