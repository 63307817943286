import React, {Component} from 'react';
import thounsandsHelper from '../../helpers/thounsandsHelper';
import { getBasePrice } from '../../helpers/basePrice';

import './OrderItem.scss'

class OrderItem extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
        const {data} = this.props;

        let palettesText=data.selectedPalettes + " Paletten je " + thounsandsHelper(data.palette ) + " Stück";
        let pu=data.selectedPackagingUnits;
        if(pu===""){
            pu=0;
        }
        let packagingUnitsText=pu + " Kartons je " + thounsandsHelper(data.packagingUnit) + " Stück";
        if(data.selectedPalettes === 1) palettesText = data.selectedPalettes + " Palette je " + thounsandsHelper(data.palette) + " Stück";
        if(data.selectedPackagingUnits === 1) packagingUnitsText = data.selectedPackagingUnits + " Karton je " + thounsandsHelper(data.packagingUnit) + " Stück";
        const selectedAmount = data.selectedPalettes*data.palette + data.selectedPackagingUnits*data.packagingUnit;
        const selectedPrice = selectedAmount * data.price;
        const { netQuantityContent, contentUnit, basePrice, priceCurrencySymbol, basePriceQuantity, basePriceUnit } = data;
        const basePriceString = getBasePrice({ netQuantityContent, contentUnit, basePrice, priceCurrencySymbol, basePriceQuantity, basePriceUnit });

        return (
            <div className="odtOrderItem odt-extendedBackground">

                <div className="odtOrderItem-spacer"/>
                <div className="odtOrderItem-image">
                    <img src={data.image} alt=""/>
                </div>

                <div className="odtOrderItem-textblock1">
                    <div className="odtOrderItem-text">{data.brand}</div>
                    <div style={{height:"2px"}}/>
                    <div className="odtOrderItem-title">{data.title}</div>
                    <div style={{height:"15px"}}/>
                    <div className="odtOrderItem-table">
                        <div className="odtOrderItem-tableL">Einzelpreis</div>
                        <div className="odtOrderItem-tableR">{data.price} €</div>
                    </div>
                    <div style={{height:"2px"}}/>
                    <div className="odtOrderItem-text">{basePriceString}</div>
                </div>

                <div className="odtOrderItem-textbock2-spacer"/>
                <div className="odtOrderItem-textblock2">
                    <div className="odtOrderItem-table">
                        <div className="odtOrderItem-tableL">Menge</div>
                        <div className="odtOrderItem-tableR">{thounsandsHelper(selectedAmount) + " Stück"}</div>
                    </div>
                    <div style={{height:"2px"}}/>
                    <div className="odtOrderItem-text">{palettesText}</div>
                    <div className="odtOrderItem-text" style={(data.packagingUnit===0 || data.packagingUnit==="0")?{display:"none"}:{display:"block"}}>{packagingUnitsText}</div>
                    <div style={{height:"15px"}}/>
                    <div className="odtOrderItem-table">
                        <div className="odtOrderItem-tableL">Warenwert</div>
                        <div className="odtOrderItem-tableR odtOrderItem-tableR-bold">{thounsandsHelper(selectedPrice, 2) + " €"}</div>
                    </div>
                </div>
                <div style={{height:"20px"}}/>
            </div>
        );
    }
}

export default OrderItem;
