import React, {Component} from 'react';
//import {Products} from 'odt-dm-patterns';
//import getAsset from "odt-dm-patterns/lib/js/components/HelperFunctions/helpers/getAsset"
import logo from '../../../assets/logo.png'
import Login from '../../components/Login';
import './StickyBar.scss'

class StickyBar extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

        this.itemRefs = {};

        this.onLogoClick=this.onLogoClick.bind(this);
        this.onBackClick=this.onBackClick.bind(this);
        this.onLoginChange=this.onLoginChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState){

    }

    //------------------------------------------------------------------------------------------------------------------
    // EVENTS

    onLogoClick(){
        this.props.onLogoClick();
    }

    onBackClick(){
        this.props.onBackClick();
    }

    //------------------------------------------------------------------------------------------------------------------
    // CUSTOM



    // EVENTS ----------------------------------------------------------------------------------------------------------

    onLoginChange(loggedIn){
        this.props.onLoginChange(loggedIn);
    }

    render(){
        const {white, back} = this.props;

        return (
            <div className="odtStickyBar odt-extendedBackground" >
                <div className="odtStickyBar-sticky" style={white?{}:{backgroundColor:"white", borderBottom:"2px solid #E5E9F1"}}>
                    <div className="odt-defaultLayout">
                        <div className="odt-defaultLayout-inner">
                            <div className="odtStickyBar-content">

                                <div className="odtStickyBar-contentItem">
                                    <div className="odtStickyBar-logo" onClick={this.onLogoClick}>
                                        <img src={logo} alt="logo"/>
                                    </div>
                                </div>
                                <div className="odtStickyBar-contentItem">
                                    <div onClick={this.onBackClick} className="odt-textButton odtStickyBar-buttonBack" style={back?{}:{display:"none"}}>←&nbsp;Zurück</div>
                                    <Login url={window.ODT.loginUrl} onLoginChange={this.onLoginChange}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default StickyBar;
