import React, {Component} from 'react';
import './Overview.scss'
import Pagination from '../../components/Pagination';
import ajaxWithToken from '../../helpers/ajaxWithToken';
//import $ from "jquery";
import moment from 'moment';

class Overview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            page: 1,
            pages:1,
            items:0,
            rows:[],
            errorMessage:"",
            errorButton:<div></div>,
            searchQuery:"",
        };

        this.onSearchClick = this.onSearchClick.bind(this);
        this.onPaginationChange = this.onPaginationChange.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.onDetailClick = this.onDetailClick.bind(this);
        this.onDeleteClick = this.onDeleteClick.bind(this);
        this.resetErrorMessage = this.resetErrorMessage.bind(this);
        this.getPage = this.getPage.bind(this);
    }

    componentDidMount() {
        this.getPage(1);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.r!==prevProps.r){
            this.getPage(this.state.page);
        }
    }

    //------------------------------------------------------------------------------------------------------------------
    // CUSTOM

    resetErrorMessage(){
        this.setState({
            errorMessage:"",
            errorButton:<div></div>,
        });
    }

    getPage(page){
        const token=window.localStorage.getItem("token");
        ajaxWithToken(window.ODT.orderUrl+"?page="+page+this.state.searchQuery, "GET", undefined, token, function(data){
            this.setState({
                errorMessage:"",
            });
            this.parseData(data);
        }.bind(this), function(data){

            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------
            if(window.ODT.debug){
                const debugData={
                    current_page: 1,
                    last_page: 1,
                    total: 2,
                    data: [
                        {
                            id: 3,
                            archived: 0,
                            created_at: "2019-05-24 15:51:32",
                            customer_table:{
                                billing_address_company: "ACME GmbH",
                                created_at: "2019-05-24 15:51:32",
                            },
                        },
                        {
                            id: 4,
                            archived: 1,
                            created_at: "2019-05-24 15:51:32",
                            customer_table:{
                                client_id: "8231",
                                company_name: "ACME GmbH",
                                created_at: "2019-05-24 15:51:29",
                            },
                        }
                    ],
                };

                this.parseData(debugData);
                return;
            }
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------

            if(data.status===401){
                this.setState({
                    errorMessage:"",
                    errorButton:<div></div>,
                });
                this.props.onLoginExpired();
            } else if(data.status===403){
                this.setState({
                    errorMessage:"Der Token ist ungültig. Bitte melden Sie sich erneut an.",
                    errorButton:<div></div>,
                });
            } else {
                //unhandled error
                console.log("ERROR");
                console.log(data);
                this.setState({
                    errorMessage:"Die Anfragen konnten nicht vom Server geladen werden.<br/>Bitte veruchen Sie es später noch einmal.",
                    errorButton:<div></div>,
                });
            }
        }.bind(this));

        this.setState({
            rows:[],
            page
        });
    }

    onSearchClick(){
        var customerId = document.getElementById("clientId").value;
        var startDate = document.getElementById("startDate").value.replace(/-/g, '.');
        var endDate = document.getElementById("endDate").value.replace(/-/g, '.');

        const customerQuery = customerId !== "" ? `&customer_query=${customerId}` : '';
        const dateFrom = startDate !== "" ? `&date_from=${startDate}` : '';
        const dateTo = endDate !== "" ? `&date_to=${endDate}`: '';
        const urlSearchParameter= customerQuery + dateFrom + dateTo;
        this.setState({
            searchQuery: urlSearchParameter,
        }, function () {
            this.getPage(1);
          });
    }

    onPaginationChange(p){
        this.getPage(p);
    }

    parseData(data){

        let rows=[];

        for (var i = 0; i < data.data.length; i++) {

            const itemData=data.data[i];
            const id=itemData.id;
            const dateString=moment(itemData.created_at).format("D.M.YYYY");

            let client="";
            if(itemData.customer_table.client_id!==undefined){
                client = itemData.customer_table.client_id +" - "+itemData.customer_table.company_name;
            } else{
                client = "Neu - "+itemData.customer_table.billing_address_company;
            }

            rows.push(<tr key={id} data-id={id} className={itemData.archived===1?"odtOverview-body-trHidden":""}>
                <td onClick={this.onDetailClick} className="odtOverview-body-link">{id}</td>
                <td>{dateString}</td>
                <td>{client}</td>
                <td className="odtOverview-additional-info">{itemData.additional_info}</td>
                <td data-id={id} data-archived={itemData.archived}>
                    <div onClick={this.onDownloadClick} className="odt-icon odt-icon-download"/>
                    <div onClick={this.onDetailClick} className="odt-icon odt-icon-details"/>
                    <div onClick={this.onDeleteClick} className="odt-icon odt-icon-delete"/>
                </td>
            </tr>);
        }
        this.setState({
            rows,
            //page: 1,
            page:data.current_page,
            pages:data.last_page,
            items:data.total,
        });
    }

    onDownloadSmallAmount() {
        const token = window.localStorage.getItem("token");
        window.open(window.ODT.downloadSmallAmountRequestsUrl + "?token=" + token, "_blank");
    }

    onDownloadClick(event){
        const token = window.localStorage.getItem("token");
        const id = event.currentTarget.parentNode.getAttribute("data-id");
        window.open(window.ODT.downloadUrl + "/" + id + "?token=" + token, "_blank");
    }

    onDetailClick(e){
        const id=e.currentTarget.parentNode.getAttribute("data-id");
        this.props.onDetailClick(id);
    }

    onDeleteClick(e){
        const id=e.currentTarget.parentNode.getAttribute("data-id");
        const archive=(e.currentTarget.parentNode.getAttribute("data-archived")==="1")?0:1;
        const token=window.localStorage.getItem("token");
        const data={
            archived:archive,
        };
        ajaxWithToken(window.ODT.orderUrl+"/"+id, "PUT", data, token, function(data){
            const {page} = this.state;
            this.setState({
                errorMessage:"",
                errorButton:<div></div>,
            });
            this.getPage(page);
        }.bind(this), function(data){
            if(data.status===401){
                this.setState({
                    errorMessage:"",
                    errorButton:<div></div>,
                });
                this.props.onLoginExpired();
            } else if(data.status===403){
                this.setState({
                    errorMessage:"Der Token ist ungültig. Bitte melden Sie sich erneut an.",
                    errorButton:<div></div>,
                });
            } else if(data.status===404){
                this.setState({
                    errorMessage:"Es ist keine Anfrage mit dieser Nummer voranden.",
                    errorButton:<div></div>,
                });
            } else if( data.status===422){
                this.setState({
                    errorMessage:"Die Validierung ist fehlgeschlagen.",
                    errorButton:<div></div>,
                });
            } else {
                //unhandled error
                console.log("ERROR");
                console.log(data);
                this.setState({
                    errorMessage:"Die Anfrage konnte nicht bearbeitet werden.<br/>Bitte veruchen Sie es später noch einmal.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px"}} onClick={this.resetErrorMessage}>OK</div>
                });
            }
        }.bind(this));
    }

    //------------------------------------------------------------------------------------------------------------------
    // EVENTS

    render(){
        const {page, pages, items, rows, errorMessage, errorButton} = this.state;
        let itemStart=(page-1)*20+1;
        let itemEnd=Math.min(items, (page)*20);
        let range=itemStart+"-"+itemEnd;
        if(itemStart>=itemEnd){
            range=itemEnd;
        }

        const InputElement = ({id, children, type, width}) => (
          <div className="odt-flexRow">
            <label htmlFor={id}>{children}</label>
            <input id={id} type={type} style={{ width: width }} />
            <button
              className="odt-icon odt-icon-delete odtOverview-clearButton"
              onClick={() => (document.getElementById(id).value = "")}
            />
          </div>
        );

        return (
            <div className="odtOverview">
                <div className="odtOverview-head odt-centered odt-extendedBackground">
                    <div style={{width:"30%"}}/>
                    <div className="odtOverview-headline">
                        <div style={{height:"40px"}}/>
                        <h2>Anfragen</h2>
                        <div style={{height:"10px"}}/>
                        <p>Zeige <b>{range}</b> von <b>{items}</b> Einträgen</p>
                        <div style={{height:"40px"}}/>
                    </div>
                    <div className="odtOverview-searchBar">
                        <InputElement id="clientId" type="text" width="100px">Kundennummer: </InputElement>
                        <InputElement id="startDate" type="date" width="140px">Datum von: </InputElement>
                        <InputElement id="endDate" type="date" width="144px">Datum bis: </InputElement>
                        <button onClick={this.onSearchClick} className="odtOverview-searchButton">
                                Suchen
                        </button>
                    </div>
                </div>
                <div className="odtOverview-body">
                    <div className="odtOverview-body-arrow"/>
                    <div style={{height:"40px"}}/>
                    <div
                        className="odt-errorMessage"
                        style={errorMessage!==""?{display:"block"}:{display:"none"}}
                        dangerouslySetInnerHTML={{__html:errorMessage}}
                    />
                    <div className="odt-centered">
                        {errorButton}
                    </div>
                    <div style={errorMessage!==""?{display:"none"}:{display:"block"}}>
                        <table>
                            <tbody>
                            <tr>
                                <th>Anfrage</th>
                                <th>Datum</th>
                                <th>Kunde</th>
                                <th>Hinweis</th>
                                <th></th>
                            </tr>
                            {rows}
                            </tbody>
                        </table>
                    </div>
                    <div style={{height:"40px"}}/>
                    <div className="odtOverview-body-arrow"/>
                </div>
                <div className="odtOverview-foot odt-centered odt-extendedBackground">
                    <div style={{height:"40px"}}/>
                    <div style={pages>1?{display:"block"}:{display:"none"}}>
                        <Pagination page={page} pages={pages} onChange={this.onPaginationChange} />
                    </div>
                    <div style={{height:"40px"}}/>
                    <div type="button" className="odt-button" onClick={this.onDownloadSmallAmount}>Kleine Mengen Anfrageliste herunterladen</div>
                    <div style={{height:"40px"}}/>
                </div>
            </div>
        );
    }
}

export default Overview;
