import React, {Component} from 'react';
import './Detail.scss'
import OrderItem from '../../components/OrderItem';
import thounsandsHelper from '../../helpers/thounsandsHelper';
import ajaxWithToken from '../../helpers/ajaxWithToken';
import moment from 'moment';

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data:{},
            orders:[],
            errorMessage:"",
            errorButton:<div></div>,
            ordersPrice:0,
        };
        this.getDetails=this.getDetails.bind(this);
        this.onDownloadClick=this.onDownloadClick.bind(this);
        this.onArchiveClick=this.onArchiveClick.bind(this);
        this.resetErrorMessage = this.resetErrorMessage.bind(this);
    }

    componentDidMount() {
        if(this.props.id!==undefined){
            this.getDetails(this.props.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.id!==prevProps.id){
            this.setState({
                data:{},
                orders:[],
                errorMessage:"",
                ordersPrice:0,
            });
            if(this.props.id!==undefined){
                this.getDetails(this.props.id);
            }
        }
    }

    //------------------------------------------------------------------------------------------------------------------
    // CUSTOM

    resetErrorMessage(){
        this.setState({
            errorMessage:"",
            errorButton:<div></div>,
        });
    }

    onDownloadClick(){
        const token=window.localStorage.getItem("token");
        const {id} = this.props;
        window.open(window.ODT.downloadUrl+"/"+id+"?token="+token, "_blank");
    }

    onArchiveClick(e){
        const {id}=this.props;
        const {data} = this.state;
        const archive=(data.archived===1)?0:1;
        const token=window.localStorage.getItem("token");
        const dat={
            archived:archive,
        };
        ajaxWithToken(window.ODT.orderUrl+"/"+id, "PUT", dat, token, function(data){
            this.setState({
                errorMessage:"",
                errorButton:<div></div>,
            });
            this.getDetails(id);
        }.bind(this), function(data){
            if(data.status===401){
                this.setState({
                    errorMessage:"",
                    errorButton:<div></div>,
                });
                this.props.onLoginExpired();
            } else if(data.status===403){
                this.setState({
                    errorMessage:"Der Token ist ungültig. Bitte melden Sie sich erneut an.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            } else if(data.status===404){
                this.setState({
                    errorMessage:"Es ist keine Anfrage mit dieser Nummer voranden.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            } else if( data.status===422){
                this.setState({
                    errorMessage:"Die Validierung ist fehlgeschlagen.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            } else {
                //unhandled error
                console.log("ERROR");
                console.log(data);
                this.setState({
                    errorMessage:"Die Anfrage konnte nicht bearbeitet werden.<br/>Bitte veruchen Sie es später noch einmal.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>
                });
            }
        }.bind(this));
    }

    getDetails(id){
        const token=window.localStorage.getItem("token");
        ajaxWithToken(window.ODT.orderUrl+"/"+id, "GET", undefined, token, function(data){
            this.setState({
                errorMessage:"",
            });
            this.parseData(data);
        }.bind(this), function(data){
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------
            if(window.ODT.debug){
                const debugData3={
                    id: 1,
                    order: [
                        {
                            baseprice: "300 ml (4,83 € je 1 l)",
                            brand: "Strassenkicker",
                            gtin: "4010355558169",
                            dan: "617639",
                            image: "https://cdn02.dm-static.com/images/mediacatalog/h4058172107719-1799245/productimage_170x260/1555654380000/4058172107719-1799245_org.png",
                            price: "1.45",
                            priceLocalized: "1,45 €",
                            title: "Duschgel Kraftpaket 3in1, 300 ml",
                            palette: "2208",
                            packagingUnit: "30",
                            maxPackagingUnits: "10",
                            selectedPalettes: "2",
                            selectedPackagingUnits: "0"
                        },
                        {
                            baseprice: "300 ml (4,83 € je 1 l)",
                            brand: "Strassenkicker",
                            gtin: "4010355558169",
                            dan: "617639",
                            image: "https://cdn02.dm-static.com/images/mediacatalog/h4058172107719-1799245/productimage_170x260/1555654380000/4058172107719-1799245_org.png",
                            price: "1.45",
                            priceLocalized: "1,45 €",
                            title: "Duschgel Kraftpaket 3in1, 300 ml",
                            palette: "2208",
                            packagingUnit: "30",
                            maxPackagingUnits: "10",
                            selectedPalettes: "0",
                            selectedPackagingUnits: "2"
                        }
                    ],
                    archived: 0,
                    created_at: "2019-05-24 15:51:28",
                    customer_table:{
                        id: 3,
                        billing_address_company: "ACME GmbH",
                        billing_address_street: "Musterallee 1",
                        billing_address_zip: "12345",
                        billing_address_city: "Musterstadt",
                        shipping_address_company: "ACME GmbH 2",
                        shipping_address_street: "Musterallee 2",
                        shipping_address_zip: "23456",
                        shipping_address_city: "Musterdorf",
                        contact_first_name: "Max",
                        contact_last_name: "Mustermann",
                        contact_email: "max@muster.de",
                        contact_phone: "0123/4567890",
                        additional_info: "Lorem ipsum dolor sit amet consectum. Lorem ipsum dolor sit amet consectum. Lorem ipsum dolor sit amet consectum.",
                        created_at: "2019-05-24 15:51:28",
                    }
                };
                const debugData4={
                    id: 1,
                    order: [
                        {
                            baseprice: "300 ml (4,83 € je 1 l)",
                            brand: "Strassenkicker",
                            gtin: "4010355558169",
                            dan: "617639",
                            image: "https://cdn02.dm-static.com/images/mediacatalog/h4058172107719-1799245/productimage_170x260/1555654380000/4058172107719-1799245_org.png",
                            price: "1.45",
                            priceLocalized: "1,45 €",
                            title: "Duschgel Kraftpaket 3in1, 300 ml",
                            palette: "2208",
                            packagingUnit: "0",
                            maxPackagingUnits: "0",
                            selectedPalettes: "2",
                            selectedPackagingUnits: "0"
                        }
                    ],
                    archived: 0,
                    created_at: "2019-05-24 15:51:28",
                    customer_table:{
                        id: 4,
                        client_id: "8231",
                        company_name: "ACME GmbH",
                        created_at: "2019-05-24 15:51:29",
                    }
                };
                if(id===3){
                    this.parseData(debugData3);
                }
                if(id===4){
                    this.parseData(debugData4);
                }
                return;
            }
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------
            //----------------------------------------------------------------------------------------------------------

            if(data.status===401){
                this.setState({
                    errorMessage:"",
                    errorButton:<div></div>,
                });
                this.props.onLoginExpired();
            } else if(data.status===403){
                this.setState({
                    errorMessage:"Der Token ist ungültig. Bitte melden Sie sich erneut an.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            } else if(data.status===404){
                this.setState({
                    errorMessage:"Es ist keine Anfrage mit dieser Nummer voranden.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            } else if( data.status===422){
                this.setState({
                    errorMessage:"Es ist ein Validierungsfehler aufgetreten.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            } else {
                //unhandled error
                console.log("ERROR");
                console.log(data);
                this.setState({
                    errorMessage:"Die Anfrage konnte nicht vom Server geladen werden.<br/>Bitte veruchen Sie es später noch einmal.",
                    errorButton:<div className='odt-button' style={{marginTop:"20px", marginBottom:"40px"}} onClick={this.resetErrorMessage}>OK</div>,
                });
            }
        }.bind(this));
    }

    parseData(data){
        let orders=[];
        let ordersPrice=0;
        for (var i = 0; i < data.order.length; i++) {
            orders.push(<OrderItem key={i} data={data.order[i]}/>);
            ordersPrice+=data.order[i].price*(parseInt(data.order[i].selectedPalettes)*parseInt(data.order[i].palette));
            ordersPrice+=data.order[i].price*(parseInt(data.order[i].selectedPackagingUnits)*parseInt(data.order[i].packagingUnit));
        }
        this.setState({
            data,
            orders,
            ordersPrice,
        })
    }

    //------------------------------------------------------------------------------------------------------------------
    // EVENTS

    render(){
        const {id} = this.props;
        const {orders, data, errorMessage, errorButton, ordersPrice} = this.state;

        let customerData={};
        if(data.customer_table!==undefined){
            customerData=data.customer_table;
        }

        let companyName="";
        let companyId="";
        let companyEmail="";

        if(customerData.client_id!==undefined){
            companyName=customerData.company_name;
            companyId=customerData.client_id;
            companyEmail=customerData.email;
        } else{
            companyName=customerData.billing_address_company;
            companyId="Neu";
        }

        let archiveString="Anfrage archivieren";
        let titleString="Anfrage";
        if(data.archived===1){
            archiveString="Anfrage wiederherstellen";
            titleString="Anfrage (archiviert)";
        }

        const dateString=moment(data.created_at).format("D.M.YYYY");
        const shippingCosts = parseFloat(data.shipping_costs);
        const totalPrice = ordersPrice + shippingCosts;
        const hasShippingAddress = [
            customerData.shipping_address_company,
            customerData.shipping_address_street,
            customerData.shipping_address_zip,
            customerData.shipping_address_city
        ].filter(Boolean).join(" ").trim() !== "";

        return (
            <div className="odtDetail">
                <div className="odtDetail-head odt-centered odt-extendedBackground">
                    <div style={{height:"40px"}}/>
                    <h2>{titleString}</h2>
                    <div style={{height:"10px"}}/>
                    <p>Anfragenummer: <b>{id}</b></p>
                    <div style={{height:"40px"}}/>
                </div>
                <div className="odtDetail-body odt-extendedBackground">
                    <div className="odtDetail-body-margin">
                        <div className="odtDetail-body-arrow" style={{backgroundColor:"#fcfdfe"}}/>
                        <div style={{height:"40px"}}/>
                        <div
                            className="odt-errorMessage"
                            style={errorMessage!==""?{display:"block"}:{display:"none"}}
                            dangerouslySetInnerHTML={{__html:errorMessage}}

                        />
                        <div className="odt-centered">
                            {errorButton}
                        </div>
                        <div style={(data.customer_table!==undefined && errorMessage==="")?{display:"block"}:{display:"none"}}>
                            <div className="odtDetail-body-spacer"/>
                            <div className="odtDetail-body-box2">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td><h3>Datum:</h3></td>
                                        <td className="odt-right">{dateString}</td>
                                    </tr>
                                    {shippingCosts === 60 ? <tr>
                                        <td><h3>Transportkosten:</h3></td>
                                        <td className="odt-right">{thounsandsHelper(shippingCosts, 2) + " €"}</td>
                                    </tr> : null}
                                    <tr>
                                        <td><h3>Gesamt-Warenwert:</h3></td>
                                        <td className="odt-right">{thounsandsHelper(totalPrice, 2)+ " €"}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="odtDetail-body-box2">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td><h3>Firmenname:</h3></td>
                                            <td className="odt-right">{companyName}</td>
                                        </tr>
                                        <tr>
                                            <td><h3>Kundennummer:</h3></td>
                                            <td className="odt-right">{companyId}</td>
                                        </tr>
                                        <tr style={customerData.client_id === undefined?{display:"none"}:{}}>
                                            <td><h3>E-Mail-Adresse:</h3></td>
                                            <td className="odt-right">
                                                <a href={"mailto:"+companyEmail}>{companyEmail}</a><br/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={customerData.client_id!==undefined?{display:"none"}:{display:"block"}}>
                                <div style={{height:"20px"}}/>
                                <div className="odtDetail-body-spacer"/>
                                <div className="odtDetail-body-box2" >
                                    <span className="odt-smallTitle">Rechnungsadresse:</span><br/>
                                    <span className="odt-smallText">
                                        {customerData.billing_address_company}<br/>
                                        {customerData.billing_address_street}<br/>
                                        {customerData.billing_address_zip} {customerData.billing_address_city}
                                    </span>
                                    <div style={{height:"20px"}}/>
                                    <span className="odt-smallTitle">Lieferadresse:</span><br/>
                                    <span className="odt-smallText">
                                        {!hasShippingAddress && "Lieferadresse entspricht Rechnungsadresse"}
                                        {customerData.shipping_address_company}<br/>
                                        {customerData.shipping_address_street}<br/>
                                        {customerData.shipping_address_zip} {customerData.shipping_address_city}
                                    </span>
                                </div>
                                <div className="odtDetail-body-box2 odtDetail-body-box2-spacing">
                                    <span className="odt-smallTitle">Ansprechpartner:</span><br/>
                                    <span className="odt-smallText">
                                        {customerData.contact_first_name} {customerData.contact_last_name}<br/>
                                        <a href={"mailto:"+customerData.contact_email}>{customerData.contact_email}</a><br/>
                                        {customerData.contact_phone}
                                    </span>
                                    <div style={{height:"20px"}}/>
                                    <span className="odt-smallTitle">Besonderheiten zur Lieferung:</span><br/>
                                    <span className="odt-smallText">
                                        {data.additional_info}
                                    </span>
                                </div>
                            </div>
                            <div style={{height:"40px"}}/>
                            {orders}
                        </div>
                        <div className="odtDetail-body-arrow"/>
                    </div>

                </div>
                <div className="odtDetail-foot odt-centered odt-extendedBackground">
                    <div style={{height:"40px"}}/>
                    <div style={(data.customer_table!==undefined && errorMessage==="")?{display:"block"}:{display:"none"}}>
                        <div className="odt-button" onClick={this.onDownloadClick}>Anfrageliste herunterladen</div>
                        <div className="odt-button-red" style={{marginLeft:"10px", marginRight:"10px"}} onClick={this.onArchiveClick}>{archiveString}</div>
                        <div style={{height:"30px"}}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Detail;
